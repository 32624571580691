/* greek */
@font-face {
  font-family: '__Syne_b40857';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(/_next/static/media/10c4eb715c61a3e6-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* latin-ext */
@font-face {
  font-family: '__Syne_b40857';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(/_next/static/media/5154c5c28c3353bb-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Syne_b40857';
  font-style: normal;
  font-weight: 400 800;
  font-display: swap;
  src: url(/_next/static/media/0acf7bf726cd1ef0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Syne_Fallback_b40857';src: local("Arial");ascent-override: 93.93%;descent-override: 27.93%;line-gap-override: 0.00%;size-adjust: 98.47%
}.__className_b40857 {font-family: '__Syne_b40857', '__Syne_Fallback_b40857';font-style: normal
}.__variable_b40857 {--font-heading: '__Syne_b40857', '__Syne_Fallback_b40857'
}

